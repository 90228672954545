<template>
  <div class="my_integral">
    <van-nav-bar
      :title="titleName"
      :left-arrow="true"
      @click-left="$router.go(-1)"
    />
    <!-- <div style="background:#fff;height:46px;width:100%"></div> -->
    <div class="title">
      <img src="@/assets/images/my_integral_bg.png" alt="" />
      <div>
        <span>可用积分</span>
        <h3>{{ allIntegral }}<span> 积分</span></h3>
        <span>积分可以当钱花~</span>
      </div>
    </div>
    <van-tabs
      v-model="active"
      line-width="64"
      color="#009A4D"
      title-active-color="#009A4D"
      sticky
      :swipeable="true"
      @change="tabsChange"
    >
      <van-tab
        v-for="(list, index) in state"
        :name="list.i"
        :title="list.title"
        :key="index"
        :duration="1"
      >
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="integralData.length ? '没有更多了' : ''"
          @load="IntegralDetailedList"
          :immediate-check="false"
          v-show="!switchover"
          offset="0"
          loading-text="加载中..."
        >
          <van-cell
            v-for="(item, i) in integralData"
            :key="i"
            @click="goDetails(item)"
          >
            <template #title>
              <div class="van-cell__title_top">
                <svg-icon :icon-class="item.type ? 'jf_icon' : 'xf_icon'" />
                <span v-if="item.type === 1">积分赠送</span>
                <span
                  v-else-if="
                    item.type === 2 || item.type === 3 || item.type === 4
                  "
                  >积分返还</span
                >
                <span v-else-if="item.type === 5">积分赠送</span>
                <span v-else-if="item.type === 6">积分任务</span>
                <span v-else-if="item.type === 7">签到赠送</span>
                <span v-else>积分抵扣</span>
              </div>
            </template>
            <template #default>
              <div>
                <span :style="item.type ? '' : 'color: #FB4E10'"
                  >{{
                    item.type === 1 ||
                    item.type === 2 ||
                    item.type === 3 ||
                    item.type === 4 ||
                    item.type === 5 ||
                    item.type === 6 ||
                    item.type === 7
                      ? "+"
                      : ""
                  }}{{ item.integral }}</span
                >
                <svg-icon icon-class="arrow_right" v-if="!item.type" />
              </div>
            </template>
            <template #label>
              <p v-if="!item.type">订单号：{{ item.remarks }}</p>
              <span>{{ item.createTime }}</span>
            </template>
          </van-cell>
          <van-empty description="" v-show="!integralData.length" />
        </van-list>
      </van-tab>
      <van-loading v-show="switchover" class="switchover_loading" />
    </van-tabs>
  </div>
</template>

<script>
import { formatDate } from "@/utils/validate";
import { mapState } from "vuex";
export default {
  name: "integral",
  data() {
    return {
      active: "",
      state: [
        {
          title: "全部积分",
          i: "",
        },
        {
          title: "积分抵扣",
          i: 0,
        },
        {
          title: "积分获取",
          i: 2,
        },
      ],
      integralData: [],
      loading: false,
      finished: false,
      pageNum: 1,
      switchover: true,
      allIntegral: 0,
      titleName: "",
    };
  },
  computed: {
    ...mapState("user", ["userInfo", "bank"]),
  },
  async created() {
    this.titleName = this.$route.query.name;
    await this.$store.dispatch("user/getCusBankFilePathList");
    if (this.bank.length)
      this.allIntegral = this.bank.find((item) => {
        return item.bankId === this.$route.query.bankId;
      }).integral;
    this.IntegralDetailedList();
  },

  methods: {
    async IntegralDetailedList() {
      let obj = {
        bankId: this.$route.query.bankId,
        userId: this.userInfo.id,
      };
      let obj2 = {
        bankId: this.$route.query.bankId,
        type: this.active,
        userId: this.userInfo.id,
      };
      const data = await this.$API.user.IntegralDetailedList({
        object: this.active === "" ? obj : obj2,
        pageNum: this.pageNum,
        pageSize: 10,
      });
      this.switchover = false;
      data.data.data.forEach((list) => {
        list.createTime = formatDate(list.createTime);
        this.integralData.push(list);
      });
      // 加载状态结束
      this.pageNum++;
      this.loading = false;

      // 数据全部加载完成
      if (this.integralData.length >= data.data.total) {
        this.finished = true;
      }
    },
    goDetails(item) {
      if (!item.type) {
        if (item.remarks.search(/XX/) > -1) {
          this.$router.push({
            name: "order-details",
            query: {
              integralDealNumber: item.remarks,
            },
          });
        } else {
          this.$router.push({
            name: "order-details",
            query: {
              dealNumber: item.remarks,
            },
          });
        }
      }
    },
    tabsChange(e) {
      this.switchover = true;
      this.integralData = [];
      this.pageNum = 1;
      this.finished = false;
      this.IntegralDetailedList();
    },
  },
};
</script>

<style lang="scss" scoped>
.my_integral {
  // position: absolute;
  // top: 46px;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // overflow-y: scroll;
  background: #fff;
  .title {
    position: relative;
    img {
      width: 100%;
      display: block;
    }
    div {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      text-align: center;
      color: #fff;
      h3 {
        font-size: 28px;
        line-height: 40px;
        span {
          font-weight: normal;
          font-size: 12px;
        }
      }
      span:last-child {
        font-size: 12px;
      }
    }
  }

  ::v-deep .van-tabs {
    position: relative;
    top: -10px;
    .van-tabs__wrap {
      height: 66px;
      border-radius: 10px 10px 0px 0px;

      .van-tab {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
      }
      .van-tabs__line {
        bottom: 30px;
      }
    }
    .van-tabs__content {
      .van-cell {
        padding: 16px 15px;
        .van-cell__title {
          flex: 70%;
          .van-cell__title_top {
            display: flex;
            align-items: center;
            font-size: 16px;
            color: #333333;
            .svg-icon {
              margin-right: 8px;
              font-size: 20px;
            }
          }
          .van-cell__label {
            padding-left: 28px;
            p {
              color: #666666;
            }
            span {
              font-size: 12px;
              color: #999999;
            }
          }
        }
        .van-cell__value {
          flex: 30%;
          div {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            span {
              font-size: 16px;
              font-weight: bold;
              color: #333333;
            }
            .svg-icon {
              font-size: 8px;
              margin-left: 2px;
              color: #fb4e10;
            }
          }
        }
      }
      .van-cell::after {
        left: 42px;
      }
      .switchover_loading {
        width: 100%;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    // .van-tabs__content::after {
    //   position: absolute;
    //   box-sizing: border-box;
    //   content: " ";
    //   pointer-events: none;
    //   right: 16px;
    //   bottom: 0;
    //   left: 42px;
    //   border-bottom: 0.01rem solid #ebedf0;
    //   -webkit-transform: scaleY(0.5);
    //   transform: scaleY(0.5);
    // }
  }
}
</style>
